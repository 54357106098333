const mutations = {
  setFilter(state, payload) {
    state.filters = payload
  },
  setPaginationSkip(state, payload) {
    state.pagination.skip = payload
  },
  setTotalItems(state, payload) {
    state.totalItems = payload
  },
  setNutritionistEngagement(state, payload) {
    state.nutritionistEngagement = payload
  },
  setIsLoading(state, payload) {
    state.isLoading = payload
  },
  setMetrics(state, payload) {
    state.metrics = payload
  }
}

export default mutations
