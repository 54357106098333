const getters = {
  getNews: state => state.items,
  getTotal: state => state.total,
  getTotalPages: state => state.totalPages,
  isLoading: state => state.loading,
  getFilters: state => state.filters,
  getPagination: state => state.pagination,
  getTotalItems: state => state.total,
  getIsLoading: state => state.loading
}

export default getters