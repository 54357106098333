import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import eventTypes from '@/events/events'

const actions = {
  async getNews({ state, commit }) {
    commit('setIsLoading', true)

    const params = {
      skip: (state.pagination.page - 1) * state.pagination.rowsPerPage,
      take: state.pagination.rowsPerPage
    }

    return req(
      api.get('/api/v1/news', { params: params }),
      response => {
        commit('setItems', response.data.items);
        commit('setTotalItems', response.data.total_items)
        //commit('SET_TOTAL_PAGES', response.data.total_pages)
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },

  async createNews({ commit }, formData) {
    commit('setIsLoading', true)

    try {
      return req(
        api.post('/api/v1/news', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }),
        () => {
          bus.emit(eventTypes.NOTIFY, {
            severity: "success",
            summary: "Novidades",
            detail: "Novidade registrada com sucesso"
          })        },
        isLoading => {
          commit('setIsLoading', isLoading)
        }
      )
    } catch (error) {
      console.error('Error creating news:', error)
      return { success: false, error }
    } finally {
      commit('setIsLoading', false)
    }
  },

  async updateNews({ commit }, { id, data }) {
    commit('setIsLoading', true)

    try {
      // Se for FormData, adicionar o ID
      if (data instanceof FormData) {
        data.append('id', id)
      } else {
        data.id = id
      }

      return req(
        api.put(`/api/v1/news/${id}`, data, {
          headers: {
            'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json'
          }
        }),
        () => {
          bus.emit(eventTypes.NOTIFY, {
            severity: "success",
            summary: "Novidades",
            detail: "Novidade atualizada com sucesso"
          })
        },
        isLoading => {
          commit('setIsLoading', isLoading)
        }
      )
    } catch (error) {
      console.error('Error updating news:', error)
      return { success: false, error }
    } finally {
      commit('setIsLoading', false)
    }
  },

  async deleteNews({ commit }, id) {
    commit('setIsLoading', true)

    try {
      api.delete(`/api/v1/news/${id}`),
        () => {
          bus.emit(eventTypes.NOTIFY, {
            severity: "success",
            summary: "Novidades",
            detail: "Novidade excluída com sucesso"
          })
        },
        isLoading => {
          commit('setIsLoading', isLoading)
        }
    } catch (error) {
      console.error('Error deleting news:', error)
      return { success: false, error }
    } finally {
      commit('setIsLoading', false)
    }
  },

  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters)

    if (filters.page) {
      commit('setPaginationPage', filters.page)
    }

    if (filters.rowsPerPage) {
      commit('setPaginationRowsPerPage', filters.rowsPerPage)
    }
  }
}

export default actions
