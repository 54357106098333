const getters = {
    getFilter(state){
		return state.filters
	},
    getPagination(state) {
		return state.pagination
	},
	getTotalItems(state) {
		return state.totalItems
	},
    getNutritionistsEngagement(state) {
        return state.nutritionistEngagement
    },
	getIsLoading(state) {
		return state.isLoading
	},
	getMetrics(state) {
		return state.metrics
	}
}

export default getters
