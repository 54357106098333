import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import eventTypes from "@/events/events"

const actions = {
  async fetchNutritionistsEngagement({ state, commit }, query) {
    let params;
    if (query) {
      params = {
        ...query,
      };
    } else {
      params = {
        ...state.filters,
        ...state.pagination,
      };
    }

    return req(
      api.get('/api/v1/nutritionist-engagement-contacts', { params: params }),
      response => {
				commit("setTotalItems", response.data.total_items);
        commit('setNutritionistEngagement', response.data.items)
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },
  async fetchNutritionistsEngagementMetrics({ commit }) {

    return req(
      api.get('/api/v1/nutritionist-engagement-contacts/metrics'),
      response => {
        commit('setMetrics', response.data)
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },
  async addContact({ commit }, id) {
    return req(
      api.post(`/api/v1/nutritionist-engagement-contacts/${id}/contact`),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "CRM - Desengajamento",
          detail: "Contato marcado como realizado"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },
  async updateContact({ commit }, data) {
    const body = {
      status: data.status,
      observation: data.observation
    }

    return req(
      api.put(`/api/v1/nutritionist-engagement-contacts/${data.id}/contact`, body),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "CRM - Desengajamento",
          detail: "Contato atualizado com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },

}

export default actions;
