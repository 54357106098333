import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  items: [],
  total: 0,
  totalPages: 0,
  loading: false,
  filters: {
    page: 1,
    rowsPerPage: 10,
    search: '',
    sortBy: 'createdAt',
    sortOrder: 'DESC'
  },
  pagination: {
    page: 1,
    rowsPerPage: 10
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}