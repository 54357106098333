import nutritionists from "./nutritionists";
import home from "./home";
import patient from "./patient";
import metrics from "./metrics";
import users from "./users";
import notifications from "./notifications";
import admin from "./admin";
import event from "./event";
import marketing from "./marketing";
import materials from "./materials";
import voucher from "./voucher";
import transaction from "./transaction";
import food from "./food";
import PushNotifications from "./PushNotifications";
import engagement from "./engagement";
import news from "./news";


export default {
    nutritionists,
    home,
    patient,
    metrics,
    users,
    notifications,
    event,
    marketing,
    materials,
    admin,
    voucher,
    transaction,
    food,
    PushNotifications,
    engagement,
    news
}
