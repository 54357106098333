const mutations = {
  SET_NEWS(state, news) {
    state.items = news
  },
  
  SET_TOTAL(state, total) {
    state.total = total
  },
  
  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = totalPages
  },
  
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters }
  },

  setIsLoading(state, payload) {
    state.loading = payload
  },

  setPaginationPage(state, payload) {
    state.pagination.page = payload
  },

  setPaginationRowsPerPage(state, payload) {
    state.pagination.rowsPerPage = payload
  },
  
  setItems(state, payload) {
    state.items = payload
  },
  
  setTotalItems(state, payload) {
    state.total = payload
  }
}

export default mutations